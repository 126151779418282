<template>
  <div>
    <el-card class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>权限管理</div>

      <div style="margin: 10px 0">
        <span style="font-size: 13px;font-weight: 400">角色名：</span>
        <el-input v-model="userInput" style="width: 200px" clearable placeholder="请输入关键字" size="mini"></el-input>
        <el-button size="mini" style="margin-left: 15px" type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </el-card>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { PowerData } from "./PowerData";
import { clickButtons, currentChanges, sizeChanges, getUserLists, DateChanges } from "./PowerMethods";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      getUserList()
    })
    let data = reactive({
      drafrList: [
        { name: '角色编号', text: 'id' },
        { name: '角色名称', text: 'roleName' },
        { name: '修改人', text: 'updateUser' },
        { name: '创建时间', text: 'createTime' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],
      tableHeight: 400,
      operation: ['编辑', '删除'],
      pageType: 1,
      lastState: 1000,
      filetype: null,

      userInput: ''
    })
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let DateChange = (val) => { DateChanges(val, data) }

    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      DateChange,
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
</style>